<template>
	<div
		class="form-group-2"
		:class="{
			active: isActive,
			disabled: isDisabled,
			error: firstError,
			focused: isFocused,
			'no-left-icon': leftIcon === false,
			'textarea': isTextArea,
			[labelStyle]: labelStyle,
		}"
	>
		<template v-if="loading">
			<input
				type="text"
				class="input"
				readonly
				ref="input"
				@focus="focus"
				@blur="blur"
			>
			<div class="input-loading">
				<div class="dot"></div>
				<div class="dot"></div>
				<div class="dot"></div>
			</div>
			<span
				class="loader-icon icon icon-left"
				v-if="leftIcon !== false"
			></span>
		</template>

		<component
			v-else
			v-bind="$attrs"
			v-on="listeners"
			:is="component"
			:type="type"
			:id="id"
			:name="name"
			:value="value"
			:placeholder="computedPlaceholder"
			:show-password="showPassword"
			ref="input"
			:class="className"
		/>

		<ion-icon
			class="icon icon-right cursor-pointer text-gray-400"
			:name="rightDefaultIcon"
			v-if="isPassword && canTogglePassword"
			@click.prevent="togglePassword"
		></ion-icon>
		<span
			class="icon icon-right caret text-gray-400"
			:name="rightDefaultIcon"
			v-else-if="isDate"
		></span>
		<ion-icon
			class="icon icon-right cursor-pointer text-gray-400"
			:name="rightIcon"
			v-else-if="rightIcon"
			@click.prevent="rightIconClick"
		></ion-icon>
		<img :src="rightIconImg" class="icon icon-right" :alt="rightIconImgAlt" v-else-if="rightIconImg">

		<label v-if="!isDateOfBirth && $slots.default" :for="id" class="label">
			<slot />
		</label>

		<div
			class="form-group-2-text"
			:class="textColor"
			v-if="firstError"
		>
			{{ firstError }}
		</div>
	</div>
</template>

<script>
export default {
	model: {
		prop: 'value',
		event: 'change'
	},
	props: {
		canTogglePassword: {
			type: Boolean,
			default: true,
		},
		data: {
			type: Object,
			default: null
		},
		form: {
			type: Object,
			default: null
		},
		id: {
			type: String,
			default: null
		},
		labelStyle: {
			type: String,
			default: null
		},
		loading: {
			type: Boolean,
			default: false
		},
		name: {
			type: String,
			default: ''
		},
		placeholder: {
			type: String,
			default: ''
		},
		rightIcon: {
			type: [String, Boolean],
			default: false
		},
		rightIconImg: {
			type: String,
			default: null
		},
		rightIconImgAlt: {
			type: String,
			default: null
		},
		rightIconClick: {
			type: Function,
			default: () => {}
		},
		type: {
			type: String,
			default: 'text'
		},
		value: {
			type: [String, Number, File],
			default: ''
		},
	},
	data() {
		return {
			isFocused: false,
			showPassword: false,
		};
	},
	computed: {
		component() {
			switch (this.type) {
				case 'bank':
					return 'input-bank';
				case 'date':
					return 'input-date';
				case 'date_of_birth':
					return 'input-date-of-birth';
				case 'employment-status':
					return 'input-employment-status';
				case 'employment-type':
					return 'input-employment-type';
				case 'file':
					return 'file';
				case 'gender':
					return 'input-gender';
				case 'id-type':
					return 'input-id-type';
				case 'marital-status':
					return 'input-marital-status';
				case 'money':
					return 'input-money';
				case 'password':
					return 'input-password';
				case 'salary-day':
					return 'input-salary-day';
				case 'select':
					return 'custom-select';
				case 'textarea':
					return 'input-textarea';
				default:
					return 'input';
			}
		},
		className() {
			switch (this.type) {
				case 'date_of_birth':
					return '';
				default:
					return 'input';
			}
		},
		computedPlaceholder() {
			if (this.isFocused || !this.$slots.default) {
				return this.placeholder;
			}
			return '';
		},
		errors() {
			return this.field?.errors;
		},
		field() {
			return this.data ? this.data : this.form && this.name && this.form.data[this.name] ? this.form.data[this.name] : null;
		},
		firstError() {
			if (this.errors) {
				const key = Object.keys(this.errors)[0];
				switch (key) {
					case 'same':
						return 'The passwords do not match';
					default:
						return this.errors[key];
				}
			} else {
				return null;
			}
		},
		isActive() {
			return this.isFocused || this.value?.toString()?.length && !this.loading;// || this.isDate;
		},
		isDate() {
			return this.type && this.type.match(/^date$/);
		},
		isDateOfBirth() {
			return this.type && this.type.match(/^date_of_birth$/);
		},
		isDisabled() {
			return this.disabled || this.loading;
		},
		isNativeInput() {
			switch (this.type) {
				case 'bank':
				case 'date':
				case 'date_of_birth':
				case 'employment-status':
				case 'employment-type':
				case 'file':
				case 'gender':
				case 'id-type':
				case 'marital-status':
				case 'money':
				case 'salary-day':
				case 'select':
				case 'textarea':
					return false;
				default:
					return true;
			}
		},
		isTextArea() {
			return this.type === 'textarea';
		},
		isPassword() {
			return this.type && this.type.match('password');
		},
		leftIcon() {
			return false;
		},
		listeners() {
			return {
				...this.$listeners,
				blur: (event) => {
					this.isFocused = false;
					this.$emit('blur', event);
				},
				change: (event) => {
					let value = event;
					if (this.isNativeInput) {
						value = event.target.value;
					}
					this.$emit('change', value);
					this.validateData();
				},
				focus: (event) => {
					this.isFocused = true;
					this.$emit('focus', event);
				},
				input: (event) => {
					let value = event;
					if (this.isNativeInput) {
						value = event.target.value;
					}
					this.$emit('change', value);
					this.validateData();
				},
			};
		},
		rightDefaultIcon() {
			return this.showPassword ? 'eye-off-outline' : 'eye-outline';
		},
		textColor() {
			if (this.errors) {
				return 'text-red-500';
			}
			return '';
		}
	},
	methods: {
		blur() {
			this.isFocused = false;
			this.$emit('blur', event);
		},
		focus() {
			this.isFocused = true;
			this.$emit('focus', event);
		},
		togglePassword() {
			this.showPassword = !this.showPassword;
			this.$refs.input.$el.focus();
		},
		validateData() {
			if (this.name && this.field) {
				let input;
				if (!this.isNativeInput) {
					input = this.$refs.input?.$el;
				}else {
					input = this.$refs.input;
				}
				this.validateField(this.name, this.field, input, this.form);
			}
		},
	}
}
</script>
